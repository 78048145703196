import {
  faHtml5,
  faCss3Alt,
  faJsSquare,
  faReact,
  faNodeJs,
  faWordpress,
  faAws,
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faGithub,
  faWhatsappSquare,
} from '@fortawesome/free-brands-svg-icons';
import {
  faMapMarkerAlt,
  faEnvelope,
  faUser,
  faAlignLeft,
  faPhoneSquareAlt,
  faBackward,
  faPlay,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import redux from '../images/redux.png';
import postgresql from '../images/postgresql.png';
import gatsby from '../images/gatsby.png';
import graphql from '../images/graphql.png';
import ps from '../images/ps.png';
import ai from '../images/ai.png';
import id from '../images/id.png';
import figma from '../images/figma.png';

export const logos = {
  html: faHtml5,
  css: faCss3Alt,
  js: faJsSquare,
  react: faReact,
  node: faNodeJs,
  wp: faWordpress,
  back: faBackward,
  aws: faAws,
  redux,
  postgresql,
  graphql,
  gatsby,
  github: faGithub,
  facebook: faFacebook,
  twitter: faTwitter,
  instagram: faInstagram,
  linkedin: faLinkedin,
  location: faMapMarkerAlt,
  phone: faPhoneSquareAlt,
  whatsapp: faWhatsappSquare,
  ai,
  ps,
  id,
  figma,
  mail: faEnvelope,
  user: faUser,
  message: faAlignLeft,
  live: faPlay,
  close: faTimesCircle,
};
